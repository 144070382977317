.height-100vh{
    height:100vh;
}
.slide-form{
    
    width: 650px;
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}
.slide-form .title{
    color : #fff;
    text-align: left;
    margin-bottom: 10px;
    padding: 10px;
}
.slide-form .text{
    background: transparent;
    color: #9c9c9c;
    border-color: #8C8269;
}
.slide-form .slide-link{
    color: #777;
}
.slide-form .slide-link:hover{
    color: #fff;
    transition: all 0.5s ease-out;
}
.slider-scroll-button{
    bottom: 0;
}
.rounded-35{
    border-radius: 35px;
}
.link-first{
    /* border-right: 1px solid #54565c; */
    margin-right: 15px;
    padding-right: 10px;
    font-size: 13px;
}
.slide-line{
    background-image: linear-gradient(270deg,#212529,#8c8269,#212529);
     width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.t4l3{
    top: 41%; left: -30%;
}
.t7l3{
    top: 75%; left: 30%;
}

@media (max-width: 900px) {
	.slide-form .text , .slide-form button{
        width: 70%;
        margin-left: 15%;
    }
}